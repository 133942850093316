@font-face {
	font-family: "DGAgnadeen-UltraLight";
	src: url("../fonts/DGAgnadeen/DGAgnadeen-Ultralight.ttf");
	font-display: swap;
}

@font-face {
	font-family: "DGAgnadeen-Regular";
	src: url("../fonts/DGAgnadeen/DGAgnadeen-Regular.ttf");
	font-display: swap;
}

@font-face {
	font-family: "DGAgnadeen-ExtraBold";
	src: url("../fonts/DGAgnadeen/DGAgnadeen-Extrabold.ttf");
	font-display: swap;
}

:root {
	--primary: #20B3D4;
	--secondary: #0D46B9;
	--dark: #172548;
	--light-dark: #788196;
	--primary-linear: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
	--ft-big: 2.5em;
	--ft-mid: 1.4em;
	--ft-sm: 1em;
	--font-nav: 'DGAgnadeen-UltraLight', Courier, monospace;
	--font-text: 'DGAgnadeen-UltraLight', Courier, monospace;
	--font-title: 'DGAgnadeen-ExtraBold', Courier, monospace;
	--font-sub-title: 'DGAgnadeen-Regular', Courier, monospace;
}



/* ----------------- Global Styles ------------ */

body,
html {
	font-family: 'Open Sans', sans-serif;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	color: #777;
	font-weight: 400;
	width: 100% !important;
	height: 100% !important;
	/* overflow-x: hidden; */
}

body {
	overflow-x: hidden;
}

h2,
h3,
h4 {
	font-family: var(--font-sub-title);
	font-weight: bold;
}

h2 {
	margin: 0 0 20px 0;
	font-weight: 800;
	font-size: 36px;
	color: #333;
}

h3 {
	font-size: 20px;
	font-weight: 600;
	color: #333;
}

h4 {
	font-size: 18px;
	color: #333;
	font-weight: 600;
}

h5 {
	text-transform: uppercase;
	font-weight: 700;
	line-height: 20px;
}

p.intro {
	margin: 12px 0 0;
	line-height: 24px;
}

a {
	color: #608dfd;
	font-weight: 400;
}

a:hover,
a:focus {
	text-decoration: none;
	color: #608dfd;
}

ul,
ol {
	list-style: none;
}

ul,
ol {
	padding: 0;
	webkit-padding: 0;
	moz-padding: 0;
}

hr {
	height: 2px;
	width: 70px;
	text-align: center;
	position: relative;
	background: #1E7A46;
	margin-bottom: 20px;
	border: 0;
}

.card-custom {
	margin: 0 8px;
	background-color: #f9f9f9;
	box-shadow: 0px 0 9px 1px #ccc;
	border-radius: 4px;
	overflow: hidden;
	padding: 2rem 1.25rem;
	line-height: 24px;
	height: auto;
}

.card-custom h3 {
	font-size: calc(var(--ft-mid)*0.9);
	color: var(--dark);
	margin-bottom: 15px;
	margin-top: 0px;
	font-family: var(--font-sub-title);
}

/* ------------------------------------------------- */
/* Navigation Styles */
.navbar {
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
	background: linear-gradient(var(--dark), transparent);
	box-shadow: unset;
	padding: 6px 0;
	transition: all .3s ease;

}

nav.nav__bg {
	background-color: var(--dark);
	box-shadow: 0 0px 5px 0px #ffffff30;
}

.navbar .navbar-nav {
	margin-left: auto;
}

.navbar-lg .nav-item {
	margin: 0 17px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-lg .nav-item select {
	background: unset;
	border: unset;
	text-transform: capitalize;
	cursor: pointer;
	padding: 0;
	width: 65px;
	color: #d9e5ff !important;
	font-family: var(--font-nav);
	font-size: calc(var(--ft-sm)*0.96);
	letter-spacing: 0.02em;
}

.navbar .nav-link {
	font-family: var(--font-nav);
	font-size: calc(var(--ft-sm)*0.96);
	letter-spacing: 0.02em;
	padding: 8px 0 !important;
	position: relative;
	display: flex;
	align-items: center;
	color: #d9e5ff !important;
	font-weight: 600;
}



.navbar-brand {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
}

.navbar-brand .logo-image {
	height: 26px;
	width: 182px;
}

.navbar-lg .nav-link:after {
	display: block;
	position: absolute;
	left: 0;
	bottom: -1px;
	width: 0;
	height: 2px;
	background: var(--primary);
	content: "";
	transition: width 0.2s;
}

.navbar-lg .nav-link:hover:after,
.navbar-lg .nav-link:active:after,
.navbar-lg .nav-link:focus:after {
	width: 100%;
}

.navbar-lg .navbar-toggler {
	margin-left: 1rem;
}

.navbar-lg .navbar-light .navbar-toggler {
	color: #e5e5e5;
	border-width: 2px;
}

/* *****************************************************  */
/* ********************* Small Navbar ******************  */
/* *****************************************************  */

.offcanvas-header img {
	height: 22px;
}


.navbar-toggler {
	outline: unset;
	box-shadow: unset;
	border: 2px solid #ffffff4a;
	color: #ffffffb5;
	padding: 5px 8px;
	background: #2a4588de;
	border-radius: 6px;
}

.navbar-toggler:focus {
	box-shadow: unset !important;
}

.navbar-sm .nav-link {
	color: #d0deff;
	font-family: var(--font-nav);
	font-size: calc(var(--ft-sm)*1);
	letter-spacing: 0.02em;
	padding: 8px 14 !important;
	background-color: #cdd4e717;
	border-radius: 3px;
	margin-bottom: 14px;
	transition: background-color .4s ease;
}

.navbar-sm select {
	font-family: var(--font-nav);
	font-size: calc(var(--ft-sm)*1);
	transition: background-color .4s ease;
	background-color: #cdd4e717;
	color: #d0deff;
	width: 100%;
	overflow: hidden;
	border-radius: 3px;
	padding: 10px;
	height: 42px;
	border: none;
}

.navbar-sm select:hover,
.navbar-sm select:focus,
.navbar-sm select:active,
.navbar-sm .nav-link:hover,
.navbar-sm .nav-link:focus,
.navbar-sm .nav-link:active {
	background-color: #cdd4e752;
}

.offcanvas {
	background-color: #172548ad;
	width: 310px;
	border-left: 1px solid rgba(0, 0, 0, .2);
}

/* .offcanvas-end {
	top: 0;
	left: 0;
	right: unset;
} */

.offcanvas .btn-close {
	background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

/* old styles */

#menu {
	padding: 15px;
	transition: all 0.8s;
}

.section-title {
	margin-bottom: 70px;
}

.section-title h2 {
	position: relative;
	margin-top: 10px;
	padding-bottom: 8px;
	font-size: calc(var(--ft-big)*1.1);
	color: var(--dark);
	font-family: var(--font-sub-title);
	letter-spacing: 0.01em;
}

.section-title>p {
	font-family: var(--font-text);
	color: #7b89b4;
	font-size: calc(var(--ft-sm) * 1.1);
	letter-spacing: 0.01em;

	margin-bottom: 0px;
}

.btn-custom {
	font-family: var(--font-text);
	text-transform: uppercase;
	color: #fff;
	/* background-image: var(--primary-linear); */
	background-color: var(--primary);
	padding: 11px 34px;
	letter-spacing: 1px;
	margin: 0;
	font-size: calc(var(--ft-sm)*0.95);
	border-radius: 25px;
	transition: all 0.2s linear;
	border: 0;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
	color: #fff;
	background-image: none;
	background-color: var(--secondary);
}

.btn:active,
.btn.active {
	background-image: none;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: none;
	outline-offset: none;
}

/* ------------------- Header Section ---------------- */


.header .intro {
	height: 750px;
	overflow: hidden;
	position: relative;
	width: 100%;
	padding: 0;
	background-color: #e5e5e5;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

.header .intro img {
	object-position: center;
	height: 100%;
	width: 100%;
}

/* .header .intro .overlay {
	background: var(--dark);
} */

.header .intro h1 {
	font-family: var(--font-title);
	color: #fff;
	font-size: calc(var(--ft-big)*1.4);
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 15px;
}

.header .intro h2 {
	font-family: var(--font-title);
	color: #fff;
	font-size: calc(var(--ft-big)*1.4);
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 10px;
}

.header .intro h1 span {
	font-weight: 800;
	color: #5ca9fb;
}

.header .intro p {
	line-height: 33px;
	color: #fff;
	font-size: calc(var(--ft-mid)*0.9);
	margin: 34px auto;
	font-family: var(--font-text);
}

.header .intro-text {
	padding-top: 280px;
	padding-bottom: 200px;
	text-align: center;
	position: relative;
	z-index: 999;
}

.swiper-con {
	height: 740px;
}

.swiper-con .swiper-wrapper,
.swiper-con .swiper-slide,
.swiper-con .swiper-slide img {
	height: 100%;
	width: 100%;
}

.swiper-con .swiper-wrapper {
	position: relative;

}

.swiper-con .swiper-wrapper img {
	animation-name: bg_moving;
	animation-duration: 7s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-fill-mode: forwards;
}

@keyframes bg_moving {
	0% {
		scale: 1;
	}

	100% {
		scale: 1.1;
	}


}

.swiper-con .overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(#00000063, transparent);
	top: 0;
	left: 0;
	z-index: 99;
}


/* ************************************************* */
/* Features Section */

#features {
	padding: 70px 0 90px;
	background: #f6f6f6;
}

#features h2 {
	margin-bottom: 55px;
}

#features h2::after {
	position: absolute;
	content: "";
	background: var(--primary);
	height: 4px;
	width: 45px;
	bottom: 0;
	margin-left: -20px;
	left: 50%;
	top: 115%;
}

#features i {
	font-size: 40px;
	margin-bottom: 20px;
	transition: all 0.5s;
	color: #fff;
	width: 100px;
	height: 100px;
	padding: 30px 0;
	border-radius: 50%;
	background: var(--primary);
}

#features p {
	font-size: var(--ft-sm);
	font-family: var(--font-text);
	color: var(--light-dark);
	line-height: 1.6rem;
}

/* About Section */
.about {
	padding: 100px 0;
}



.about .about-main {
	gap: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.about .about-main>div:first-child {
	flex: .4;
	border-radius: 2px;
	overflow: hidden;
	height: 300px;
	box-shadow: -13px 13px 0px 0px rgb(32, 179, 212);

}




.about .about-main>div:first-child img {
	width: 100%;
	height: 100%;
	object-position: center;
	background: #fff;
	border-right: 0;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}

.about .about-main>div:last-child {
	flex: .60;
}

.about h3 {
	font-size: 22px;
	margin: 0 0 20px 0;
}

.about h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
	font-family: var(--font-sub-title);
	font-size: calc(var(--ft-big)*1);
	color: var(--dark);
}

.about h2::after {
	position: absolute;
	content: "";
	background: var(--primary);
	height: 4px;
	width: 13%;
	bottom: 0;
	left: 0;
	top: 98%;
}

.about .about-text li {
	margin-bottom: 6px;
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.about .about-text li>div:first-child {
	padding-left: 8px;
	font-size: .9rem;
	color: var(--primary)
}

.about .about-text li>div:last-child {
	font-family: var(--font-text);
}


/* .about .about-text li:before {
	content: '\f00c';
	font-family: 'FontAwesome';
	color: #5ca9fb;
	font-size: 11px;
	font-weight: 300;
	padding-right: 8px;
} */



.about p {
	margin: 20px 0 5px 0;
	font-size: calc(var(--ft-sm)*1.05);
	font-family: var(--font-text);
	letter-spacing: 0.01em;
	line-height: 1.8em;
	color: var(--light-dark);
}

/* ========================================= */
/* Services Section */
/* ========================================= */

#services {
	padding: 75px 0;
	background: var(--dark);
	color: #fff;
}

#services .service-desc {
	margin-bottom: 40px;
	padding: 35px 30px;
	background: #fff;
	border-radius: 4px;
	transition: transform 0.3s ease;
}

.service-desc:hover {
	transform: scale(.95);
}



#services h2 {
	color: #fff;
	font-size: calc(var(--ft-big)*1.2);
	margin-bottom: 5px;
}

#services i {
	font-size: 67px;
	padding: 0px;
	border-radius: 50%;
	color: var(--primary);
	margin-bottom: 10px;
}

#services h3 {
	font-weight: 500;
	padding: 0px 15px 0;
	color: var(--dark);
	margin-top: 14px;
	line-height: 26px;
	font-size: calc(var(--ft-sm)*1.15);
	text-transform: capitalize;
}

#services div p {
	font-family: var(--font-text);
	letter-spacing: 0.025em;
	font-size: calc(var(--ft-sm)*.95);
	line-height: 25px;
	color: #fff;
	opacity: .6;
	margin-top: 15px;
	padding: 0 15px;
}

#services .section-title>p {
	line-height: 28px;
	color: #7b89b4;
	font-family: var(--font-text);
	letter-spacing: 0.02em;
	font-size: calc(var(--ft-sm)*1.02);
	opacity: 1;
	margin-top: 10px;

}

#services .service-desc {
	margin-bottom: 40px;
	height: 228px;
	padding: 35px 22px;
}

/* Portfolio Section */

#portfolio {
	padding: 100px 0;
}

#portfolio h2 {
	/* margin-bottom: 10px; */
}

#portfolio p {
	font-family: var(--font-text);
	color: var(--light-dark);
	letter-spacing: 0.02em;
	font-size: calc(var(--ft-sm)*1.1);
	margin-top: 10px;
}

.portfolio-item {
	padding: 0;
	height: 260px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.portfolio-item img {
	height: 100%;
	width: 100%;
}

.portfolio-item .hover-text {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	text-align: center;
	margin: 0 auto;
	color: #fff;
	background: #20B3D461;
	opacity: 0;
	height: 100%;
	width: 100%;
	transition: all 0.5s;
}

.portfolio-item .hover-text>h4 {
	opacity: 0;
	color: #fff;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
	transition: all 0.3s;
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: 500;
	text-transform: uppercase;
}

.portfolio-item:hover .hover-text>h4 {
	opacity: 1;
	backface-visibility: hidden;
	transform: translateY(0);
	-webkit-transform: translateY(0);
}

.portfolio-item:hover .hover-text {
	opacity: 0.85;
}

/***** Team Section  *****/

#team h2 {
	margin-bottom: 10px;
}

#team>p {
	margin-bottom: 20px;
}

#team .caption h4 {
	font-family: var(--font-nav);
	color: var(--dark);
	font-size: calc(var(--ft-sm)*1.25);
	margin-top: 12px;
}

#team .caption p {
	color: var(--light-dark);
	font-size: var(--ft-sm);
	font-family: var(--font-text);
	letter-spacing: 0.025em;
	margin-top: 3px;
}

/* Testimonials Section */

#testimonials {
	padding: 100px 0;
	background: #f6f6f6;
}

#testimonials h2 {
	margin-bottom: 10px;
}

#testimonials i {
	color: #e6e6e6;
	font-size: 32px;
	margin-bottom: 20px;
}

.testimonial {
	position: relative;
	padding: 20px;
}

.testimonial-image {
	float: left;
	margin-right: 15px;
}

.testimonial-image {
	display: block;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	overflow: hidden;
}

.testimonial-image img {
	width: 100%;
	height: 100%;
}

.testimonial-content {
	position: relative;
	overflow: hidden;
}

.testimonial-content p {
	margin-bottom: 3px;
	font-size: calc(var(--ft-sm)*0.95);
	font-family: var(--font-text);
	line-height: 1.7em;
	color: var(--light-dark);
}

.testimonial-meta {
	margin-top: 10px;
	font-size: var(--ft-sm);
	color: var(--dark);
	font-family: var(--font-text);
}

/* Team Section */
.team {
	padding: 40px 0;
}

.team h4 {
	margin: 5px 0 0px 0;
}


.team .thumbnail {
	margin: auto;
	background: transparent;
	border: 0;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	overflow: hidden;
}

.team .team-img {
	width: 100%;
	height: 100%;
}

.team .caption {
	padding: 10px 0 0 0;
	color: #888;
}

/* Contact Section */
.contact {
	padding: 75px 0 60px 0;
	background: var(--dark);
	color: rgba(255, 255, 255, .75);
}

.contact .section-title {
	margin-bottom: 30px;
}

.contact .section-title p {
	font-size: 16px;
}

.contact h2 {
	color: #fff;
	margin-top: 0;
	margin-bottom: 22px;
	font-size: calc(var(--ft-mid)*1.1);
	border-bottom: 1px solid #2c3c63;
	padding-bottom: 20px;
	font-family: var(--font-sub-title);
	margin-top: 20px;
}

.contact h3 {
	color: #fff;
	padding-bottom: 20px;
	font-size: calc(var(--ft-mid)*1.05);
	border-bottom: 1px solid #2c3c63;
	font-family: var(--font-sub-title);
	margin-bottom: 22px;
	margin-top: 20px;
}


.contact .text-danger {
	color: #cc0033;
	text-align: left;
}

.contact .btn-custom {
	margin: 20px 0;
	background: var(--primary);
	color: var(--dark);
	letter-spacing: 0.02em;
	font-size: var(--ft-sm);
	font-family: var(--font-text);
	padding: 10px 50px;
	transition: all 0.2s ease-in-out;
}

.contact .btn-custom:hover {
	background: #fff;
}

label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}

.contact .form-control::placeholder {
	color: #7885aa;
}

.contact .form-control {
	font-family: var(--font-text);
	border-radius: 3px;
	display: block;
	width: 100%;
	font-size: var(--ft-sm);
	line-height: 1.42857143;
	color: #bbc2d5;
	background-color: #1e305d;
	transition: all .2s ease;
	border: none;
	padding: 15px 20px;
}

.contact .form-control:focus {
	outline: 0;
	box-shadow: none;
	background-color: #233668;
}

.form-control::-webkit-input-placeholder {
	color: #777;
}

.form-control:-moz-placeholder {
	color: #777;
}

.form-control::-moz-placeholder {
	color: #777;
}

.form-control:-ms-input-placeholder {
	color: #777;
}

.contact .contact-item p {
	margin-top: 1.8em;
	margin-bottom: 1.8rem;
	font-family: var(--font-text);
	color: #7b89b4;
}

.contact .contact-item span {
	color: #fff;
	margin-bottom: 8px;
	display: block;
	font-family: var(--font-nav);
	font-size: var(--ft-sm);
	letter-spacing: 0.02em;
}

.contact .contact-item i {
	margin-right: 5px;
	color: var(--primary);
	vertical-align: -0.1em;
}

.contact .contact-item p a {
	color: var(--primary);
	text-decoration: underline;
}

.contact .contact-item p a:hover {
	text-decoration: underline;
	color: #fff;
}

.contact .social {
	border-top: 1px solid rgba(255, 255, 255, 0.15);
	padding-top: 50px;
	margin-top: 50px;
	text-align: center;
}

.contact .social ul li {
	display: inline-block;
	margin: 0 20px;
}

.contact .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid #fff;
	color: #fff;
	border-radius: 50%;
	transition: all 0.3s;
	display: inline-block;
}

.contact .social i.fa:hover {
	background: #fff;
	color: var(--primary);
}

/* Footer Section*/
.footer {
	background: #fff;
	padding: 30px 0 10px 0;
}

.footer p {
	color: #777;
	font-size: 14px;
	text-transform: capitalize;
}

.footer a {
	text-decoration: none;
	color: var(--dark);
}

.footer a:hover {
	color: #209DBB;
	text-decoration: underline;
}

.cursor {
	cursor: pointer;
}

/* ----------------------- Portfolio Section -------------- */

.portfolio-items {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}


/* ******************* Whatsapp Icon ***************** */
.whatsapp-icon {
	position: fixed !important;
	bottom: 15px;
	left: 20px;
	width: 70px;
	height: 70px;
	z-index: 9999;
	background: var(--primary);
	border-radius: 20px;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;

	display: flex;
	justify-content: center;
	align-items: center;

	transition: all .3s ease-in-out;
	text-decoration: none;
	opacity: .75;
}

.whatsapp-icon:hover,
.whatsapp-icon:focus,
.whatsapp-icon:active {
	opacity: 1
}

.whatsapp-icon i {
	color: var(--dark);
	font-size: 3.2em;
	margin-bottom: 4px;
	margin-left: 3px;
}

@media (max-width: 992px) {
	.swiper-con {
		height: 550px;
	}

	.navbar {
		height: auto;
	}

	.container-lg {
		padding: 0 !important;
	}

	.navbar-nav {
		padding: 0;
		/* box-shadow: 0px 5px 12px 0px #cccccc85; */
	}

	/* ------------- Header -------------- */
	.header .intro {
		height: 475px;
	}

	.header .intro-text {
		padding-top: 10rem;
		padding-bottom: 8rem;
	}

	.header .intro-text h1 {
		font-size: 3rem;
	}

	.header .intro-text h2 {
		font-size: 2.6rem;
	}

	/* ----------- Features Section -------------- */

	#features h2 {
		margin-bottom: 45px;
	}

	#features h2::after {
		height: 3px;
		width: 35px;
		margin-left: -16px;
		top: 109%;
	}

	.card-custom {
		margin: 8px;
		margin-bottom: 20px;
		padding: 1.5rem 1.25rem;
	}

	.card-custom h3 {
		font-size: calc(var(--ft-mid)*1);
	}

	#features i {
		font-size: 60px;
		margin-bottom: 30px;
		width: 130px;
		height: 130px;
		padding: 33px 0;
	}


	/* ----------- About Section -------------- */
	.about {
		padding: 68px 0;
	}

	.about img {
		width: 100%;
	}

	.about h2 {
		padding-bottom: 10px;
	}

	.about h2::after {
		width: 9%;
		height: 3px;
		top: 100%;
	}

	.about .about-main {
		flex-direction: column;
		align-items: unset;
	}

	.about .about-main>div:first-child {
		height: 420px;
		box-shadow: none;
	}

	#services {
		padding: 55px 10px;
	}

	#services .section-title {
		margin-bottom: 45px;
	}

	/* ----------------- Global ---------------- */
	h2 {
		font-size: 1.9rem;
	}
}


@media (max-width: 768px) {

	.swiper-con {
		height: 425px;
	}


	.header .intro {
		height: 400px;
	}

}


@media (max-width: 480px) {


	/* ******************** Header ***********************/

	.swiper-con {
		height: 265px;
	}

	.header .intro {
		height: 305px;
	}

	.section-title h2 {
		font-size: calc(var(--ft-big)*.9);
		text-align: center;
	}

	/* ----------- Features Section -------------- */

	#features {
		padding: 40px 0 50px;
	}

	.card-custom {
		margin: 8px;
		margin-bottom: 20px;
	}

	.card-custom h3 {
		font-size: calc(var(--ft-mid)*0.85);
		margin-bottom: 12px;
	}

	#features i {
		font-size: 41px;
		margin-bottom: 23px;
		width: 100px;
		height: 100px;
		padding: 31px 0;
	}

	#features h2::after {
		height: 3px;
		width: 35px;
		margin-left: -15px;
		top: 112%;
	}

	#features p {
		font-size: calc(var(--ft-sm)*.98);
		line-height: 1.65rem;
		letter-spacing: 0.015em;
	}

	/* ----------- About Section -------------- */

	.about {
		padding: 55px 0 50px;
	}

	.about .about-main {
		padding: 0 10px;
	}

	.about h2 {
		font-size: calc(var(--ft-big)*.9);
	}

	.about h2::after {
		top: 99%;
		height: 3px;
		width: 15%;
	}

	.about p {
		line-height: 1.8rem;
		font-size: calc(var(--ft-sm)*.95);
		letter-spacing: 0.02em;
	}

	#services h2 {
		font-size: calc(var(--ft-big)*.95);
	}

	#services .section-title>p {
		line-height: 1.5em;
		font-size: calc(var(--ft-sm)*1);
	}

	#services h3 {
		font-size: calc(var(--ft-sm)*1.15);
		letter-spacing: 0.02em;
	}

	#services div p {
		letter-spacing: 0.02em;
		font-size: calc(var(--ft-sm)*.9);
	}

	#portfolio p {
		font-size: calc(var(--ft-sm)*.9);
		line-height: 1.75em;
	}

	.portfolio-items>div {
		width: 100%;
		margin-bottom: 18px;
	}

	.portfolio-item .portfolio-items {
		height: 285px;
	}

	.section-title>p {
		font-size: calc(var(--ft-sm) * .9);
		letter-spacing: 0.02em;
		line-height: 1.5em;
	}

	.contact {
		padding: 75px 10px 60px;
	}

	.contact .btn-custom {
		display: block;
		margin: 20px auto 10px;
	}

}