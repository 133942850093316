.ar {
    direction: rtl !important;
}


.ar .navbar-nav {
    margin-right: auto;
    margin-left: unset;
}

.ar .about h2::after {
    right: 0;
    left: unset;
}


/* ****************** Gallery Section ************** */
.ar .react-simple-image-viewer__previous {
    right: 0 !important;
    left: unset !important;
}

.ar .react-simple-image-viewer__next {
    left: 0 !important;
    right: unset !important;
}


/* ***************** Contact Section ************* */
.ar .contact .contact-item i {
    margin-right: unset;
    margin-left: 7px;
}


/* *********************** Nav Bar ******************** */
/* .ar .offcanvas-end {
    right: 0;
    left: unset;
} */

/* *********************** Features Section ******************** */

.ar #features p {
    line-height: 1.75rem;
}

/* *********************** About Section ******************** */

.ar .about .about-main>div:first-child {
    box-shadow: 13px 13px 0px 0px rgb(32, 179, 212);
}

.ar .about p {
    margin: 23px 0 5px 0;
    font-size: calc(var(--ft-sm)*1.05);
    font-family: var(--font-text);
    letter-spacing: 0.01em;
    line-height: 2em;
    color: var(--light-dark);
}

/* *********************** Services Section ******************** */

.ar #services h3 {
    line-height: 28px;
    font-size: calc(var(--ft-sm)*1.05);
}

@media (max-width: 992px) {

    .ar #features h2::after {
        height: 3px;
        width: 35px;
        margin-left: -16px;
        top: 112%;
    }

    .ar .about .about-main>div:first-child {
        box-shadow: none;
    }

    .ar .about h2 {
        padding-bottom: 15px;
    }

    .ar .about p {
        font-size: calc(var(--ft-sm)*1);
    }

}

@media (max-width: 480px) {

    /* ----------- Features Section -------------- */

    .ar #features p {
        line-height: 1.9em;
        font-size: calc(var(--ft-sm)*.95);
    }

    .ar .card-custom {
        margin: 8px;
        margin-bottom: 20px;
      
    }

    .ar .about p {
        font-size: calc(var(--ft-sm)*.95);
    }

    .ar .contact .section-title p {
        font-size: calc(var(--ft-sm)*.9);
        line-height: 1.7em;
        letter-spacing: 0.02em;
    }

}